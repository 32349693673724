import { Link } from "gatsby"
import React, { useState } from "react"
import FAIREStudent from "../components/fairestudent"
import StudentForm from "../components/forms/student"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { fairesData } from "../_mockdata/fairesData"

const student = require("../images/student.jpg")

const StudentPage = () => {
  const [applying, setApplying] = useState(false)
  return (
    <Layout>
      <SEO title="Student" description="" lang="en" meta={[]} />
      <div className="title-banner student">
        <h1>Student</h1>
        <h3>
          Supporting promising students seeking to make an impact in the world.
        </h3>
      </div>

      {/* Student Section */}
      <section className="bg-darkened">
        <div className="container">
          <div className="row align-items-start justify-content-between mb-5">
            <div className="col-lg-4">
              <h3 className="bold">Working with Flapmax</h3>
              <p>
                From high-schoolers to doctoral students, we support students
                who are engaged in innovative research. Relevant research areas
                include topics related to computer science and engineering.
              </p>

              <h3 className="bold mb-3 mt-5">Our Focus Areas</h3>
              <div
                className="accordion accordion-flush student-focus"
                id="accordionFlushExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                    >
                      Data
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      As with all 4 of our focus areas, data is fundamental to
                      Applied AI, enabling key insights through the application
                      of advanced machine learning algorithms in areas such as
                      data mining, data integration, graph analytics, data
                      science, and data management.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwo"
                      aria-expanded="false"
                      aria-controls="flush-collapseTwo"
                    >
                      Scientific ML Applications
                    </button>
                  </h2>
                  <div
                    id="flush-collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingTwo"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      With the rapid increase in artificial intelligence
                      technologies in many science and engineering domains such
                      as computer vision, language understanding, and
                      recommender systems, HPC applications are beginning to
                      adopt AI for a variety of scientific problems including
                      weather &amp; climate studies, high-energy physics,
                      bioinformatics, and computational chemistry.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseThree"
                      aria-expanded="false"
                      aria-controls="flush-collapseThree"
                    >
                      System Software
                    </button>
                  </h2>
                  <div
                    id="flush-collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingThree"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      From sensors and IoT devices to edge processing and cloud
                      management and storage, disparate systems (including both
                      hardware and software) must interoperate seamlessly to
                      solve highly complex tasks within tolerable limits like
                      latency, bandwidth and power while delivering rich
                      experiences for users.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingFour">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseFour"
                      aria-expanded="false"
                      aria-controls="flush-collapseFour"
                    >
                      Hardware
                    </button>
                  </h2>
                  <div
                    id="flush-collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingFour"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      Heterogeneous computing hardware will play an important
                      role in next-gen mission critical systems. Combining Data,
                      SciML and Software together, hardware needs to evolve to
                      support fast changing demands in industry, including areas
                      such as hardware acceleration, 5G platforms,
                      reconfigurable computing and SoC.
                    </div>
                  </div>
                </div>
              </div>

              <h3 className="bold mt-5">Related Links</h3>
              <ul>
                <li>
                  <Link to="/educator">Educator Page</Link>
                </li>
                <li>
                  <Link to="/industry">Industry Page</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg">
              <h2 className="text-center mb-5">
                Flapmax AI Research Fellowship
              </h2>

              <div className="d-flex mb-4 bottom-border">
                <h3
                  className={`bold mb-0  ${
                    !applying ? "tab-head active" : "tab-head"
                  }`}
                  onClick={() => setApplying(false)}
                >
                  2021 Fellows
                </h3>
                <h3
                  className={`bold mb-0 ${
                    applying ? "tab-head active" : "tab-head"
                  }`}
                  onClick={() => setApplying(true)}
                >
                  Apply
                </h3>
              </div>
              {applying ? (
                <StudentForm purpose="Collaboration" />
              ) : (
                <div>
                  {fairesData.map((fellow, index) => (
                    <FAIREStudent fellow={fellow} key={index} />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default StudentPage
