import React, { useState } from "react"
import { MdArrowDropDown } from "react-icons/md"

interface Props {
  fellow: {
    name: string
    role: string
    education: string
    testimonial: JSX.Element
    testimonialShort: JSX.Element

    image?: string
  }
}

const FAIREStudent = ({ fellow }: Props) => {
  const [display, setDisplay] = useState(false)

  return (
    <div className={`faire-card ${display ? "expanded" : ""}`}>
      <div className="img-div">
        <img src={fellow.image} alt="" className="img-fluid" />
      </div>
      <div className="text-div">
        <h3>{fellow.name}</h3>
        <h4>{fellow.education}</h4>
        <h6>{fellow.role}</h6>
        {display ? (
          <p className="testimonial-wrapper">
            {fellow.testimonial.props.children}
            <button
              onClick={e => {
                e.stopPropagation()
                setDisplay(false)
              }}
            >
              <MdArrowDropDown className="flipped" />
            </button>
          </p>
        ) : (
          <p className="testimonial-wrapper">
            {fellow.testimonialShort.props.children}...
            <button
              className="d-inline"
              onClick={e => {
                e.stopPropagation()
                setDisplay(true)
              }}
            >
              <MdArrowDropDown />
            </button>
          </p>
        )}
      </div>
    </div>
  )
}

export default FAIREStudent
