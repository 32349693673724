import React from "react"
const rishabh = require("../images/rishabh.jpg")
const travis = require("../images/travis.jpg")
const stanley = require("../images/stanley.jpg")
const neel = require("../images/neel.jpg")

export const fairesData = [
  {
    image: rishabh,
    name: "Rishabh Parekh",
    role: "Software & Data",
    education: "University of Texas at Austin, CS Junior",
    testimonialShort: (
      <p>
        I developed a tremendous amount as a professional while I was a Flapmax
        AI Research (FAIRES) fellow
      </p>
    ),
    testimonial: (
      <p>
        I developed a tremendous amount as a professional while I was a Flapmax
        AI Research (FAIRES) fellow. My team allowed me to work on projects I
        found most appealing, boosting my software engineering skills, research
        skills, and communication skills. My thoughts and work were valued every
        day by my mentor and team; each bit contributed to the growth of the
        organization. It was a fantastic work experience that I wish everyone
        could have!
      </p>
    ),
  },
  {
    image: travis,
    name: "Travis Young",
    role: "Data Mining & Graphs",
    education: "Tennessee State University, CS Senior",
    testimonialShort: (
      <p>
        Over the course of the last few months, I have been working on a project
        under the mentorship
      </p>
    ),
    testimonial: (
      <p>
        Over the course of the last few months, I have been working on a project
        under the mentorship of the FAIRES team at Flapmax. In doing this
        project, I have developed several technical and non-technical skills.
        These skills, which aren't always prioritized in academia, are important
        to those who are active in the industry. In aiding me in my development
        of these skills, my mentor has seemingly bridged the gap between
        academia and industry. I can't thank him enough!
      </p>
    ),
  },
  {
    image: stanley,
    name: "Stanley Zhang",
    role: "Natural Language Processing",
    education: "Sir Winston Churchill High School · Canada",
    testimonialShort: (
      <p>
        As an aspiring machine learning engineer, finding an internship was
        impossible. However
      </p>
    ),
    testimonial: (
      <p>
        As an aspiring machine learning engineer, finding an internship was
        impossible. However, Flapmax saw my potential and offered me a position
        as a FAIRES fellow. I had the opportunity to work with real world data
        and learn the end-to-end workflow of a real machine learning project. My
        team allowed me to take my experience from competitions and hackathons
        and adapt to long term and impactful projects. I worked on{" "}
        <a href="https://www.langauge.org/" target="_blank">
          Flapmax LanGauge
        </a>
        , an open-source natural language processing library, to do named entity
        recognition on various biomedical datasets.
      </p>
    ),
  },
  {
    image: neel,
    name: "Neel Redkar",
    role: "Deep Learning",
    education: "Dougherty Valley High School · California",
    testimonialShort: (
      <p>
        I have a passion for data science. I’ve always cultivated this passion
      </p>
    ),
    testimonial: (
      <p>
        {" "}
        I have a passion for data science. I’ve always cultivated this passion
        in private, and I can’t wait to learn more about the research aspect of
        data science as a Flapmax AI Research Fellow, as well as collaborate
        with mentors on a novel project!{" "}
      </p>
    ),
  },
]
